import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { BandwidthIco } from "../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds, TestConfiguration } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../helpers/utils";
import { ISpeed, ITestsResult } from "twillio-tests/core/TestResults";
import { Typography, withStyles } from "@material-ui/core";
import { ArrowDownIcon, ArrowUpIcon } from "./NewTests/UI/Icons";
import ResultRow from "./UI/ResultRow";

interface IBandwidthTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  config?: TestConfiguration;
  classes?: any;
}

const initialValues: ISpeed = {
  download: undefined,
  jitter: undefined,
  upload: undefined,
  region: undefined,
};

const styles = (theme: any): any => ({
  testContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    padding: "27px 16px",
    gap: "24px",
  },
  resultWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  resultContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
  },
  resultSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titleWithIcon: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    color: theme.newPalette.text.primary,
  },
  titleColor: {
    color: theme.newPalette.text.primary,
    textTransform: "capitalize",
  },
  variantDisplay: {
    fontSize: "38px",
    fontWeight: 700,
    lineHeight: 1.5,
    letterSpacing: "-0.5px",
  },
  oneLineWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    "& > :not(:first-child)": {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderTop: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
  unitColor: {
    color: theme.newPalette.text.secondary,
  },
});

const Mbps = (value: string | number) => `${formatNumber(Number(value))} <span>Mbps</span>`;

const BandwidthBestTest = (props: IBandwidthTestProps) => {
  const { mainColor, testResult, theme, colorThresholds, knowledgeUrl, estimateTime, testName, config, classes } = props;
  const speedTest = testResult && testResult.speedTest ? testResult.speedTest.best : initialValues;
  const currentThresholds = colorThresholds?.BandwidthSpeed || null;

  const { download, jitter, upload, region, error } = speedTest;

  const defaultProps = {
    mainColor,
    color: config?.useNewWidgetDesign ? theme.newPalette.text.primary : theme.customColors.successColor,
    formatting: formatNumber,
  };

  const colorTypes = {
    errorColor: config?.useNewWidgetDesign ? theme.newPalette.error.main : theme.customColors.errorColor,
    warningColor: config?.useNewWidgetDesign ? theme.newPalette.warning.main : theme.customColors.warningColor,
    successColor: config?.useNewWidgetDesign ? theme.newPalette.text.primary : theme.customColors.successColor,
  };

  const { t } = useTranslation(["common", "tests"]);
  return (
    <TestHeader
      icon={<BandwidthIco />}
      title={t("tests:bandwidth.title")}
      tooltip={t("tests:bandwidth.tooltip")}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
    >
      {config?.useNewWidgetDesign ? (
        <div className={classes.testContainer}>
          <div className={classes.resultWrapper}>
            <div className={classes.resultContainer} id="BWTA-r-downlink">
              <div className={classes.titleWithIcon}>
                <ArrowDownIcon />
                <Typography variant="body1" className={classes.titleColor}>
                  Download
                </Typography>
              </div>
              <div className={classes.resultSection} id="BWTA-c-downlink">
                <Typography
                  className={classes.variantDisplay}
                  style={{
                    color:
                      currentThresholds && currentThresholds.download
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: download,
                              warningValue: currentThresholds.download.warning,
                              errorValue: currentThresholds.download.error,
                            },
                            currentThresholds.download.isLess
                          )
                        : defaultProps.color,
                  }}
                >
                  {Number(download) <= 3 ? download : formatNumber(Number(download))}
                </Typography>
                <Typography variant="body2" className={classes.unitColor}>
                  Mbps
                </Typography>
              </div>
            </div>
            <div className={classes.resultContainer} id="BWTA-r-uplink">
              <div className={classes.titleWithIcon}>
                <ArrowUpIcon />
                <Typography variant="body1" className={classes.titleColor}>
                  Upload
                </Typography>
              </div>
              <div className={classes.resultSection} id="BWTA-c-upload">
                <Typography
                  className={classes.variantDisplay}
                  style={{
                    color:
                      currentThresholds && currentThresholds.upload
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: upload,
                              warningValue: currentThresholds.upload.warning,
                              errorValue: currentThresholds.upload.error,
                            },
                            currentThresholds.upload.isLess
                          )
                        : defaultProps.color,
                  }}
                >
                  {Number(upload) <= 3 ? upload : formatNumber(Number(upload))}
                </Typography>
                <Typography variant="body2" className={classes.unitColor}>
                  Mbps
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.oneLineWrapper}>
            <ResultRow
              label={t("jitter")}
              value={jitter}
              variant="one-line"
              color={
                currentThresholds && currentThresholds.jitter
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: jitter,
                        warningValue: currentThresholds.jitter.warning,
                        errorValue: currentThresholds.jitter.error,
                        warningValueMax: currentThresholds.jitter.warningMax,
                        warningValueMin: currentThresholds.jitter.warningMin,
                      },
                      currentThresholds.jitter.isLess
                    )
                  : defaultProps.color
              }
              id="BWTA-r-jitter"
              valueId="BWTA-c-jitter"
            />
            <ResultRow
              label={t("tests:bandwidthspeed:best-region")}
              value={region}
              variant="one-line"
              id="BWTA-r-region"
              valueId="BWTA-c-region"
              classes={classes.valueColor}
              color={config?.theme === "salesloft" ? "#054a2e" : colorTypes.successColor}
            />
          </div>
        </div>
      ) : (
        <div className="tests-card-content tests-card-content--min tst-bandwidth-speed">
          <table>
            <tbody>
              <tr className="tst-best-region" id="BWTA-r-region">
                <td>{t("tests:bandwidthspeed:best-region")}</td>
                <td id="BWTA-c-region">
                  <RenderValues {...defaultProps} value={region} />
                </td>
              </tr>
              <tr className="tst-uplink" id="BWTA-r-uplink">
                <td>{t("uplink")}</td>
                <td id="BWTA-c-upload">
                  <RenderValues
                    {...defaultProps}
                    value={upload}
                    formatting={Mbps}
                    color={
                      currentThresholds && currentThresholds.upload
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: upload,
                              warningValue: currentThresholds.upload.warning,
                              errorValue: currentThresholds.upload.error,
                            },
                            currentThresholds.upload.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
              <tr className="tst-downlink" id="BWTA-r-downlink">
                <td>{t("downlink")}</td>
                <td id="BWTA-c-downlink">
                  <RenderValues
                    {...defaultProps}
                    value={download}
                    formatting={Mbps}
                    color={
                      currentThresholds && currentThresholds.download
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: download,
                              warningValue: currentThresholds.download.warning,
                              errorValue: currentThresholds.download.error,
                            },
                            currentThresholds.download.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
              <tr className="tst-jitter" id="BWTA-r-jitter">
                <td>{t("jitter")}</td>
                <td id="BWTA-c-jitter">
                  <RenderValues
                    {...defaultProps}
                    value={jitter}
                    color={
                      currentThresholds && currentThresholds.jitter
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: jitter,
                              warningValue: currentThresholds.jitter.warning,
                              errorValue: currentThresholds.jitter.error,
                              warningValueMax: currentThresholds.jitter.warningMax,
                              warningValueMin: currentThresholds.jitter.warningMin,
                            },
                            currentThresholds.jitter.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </TestHeader>
  );
};

export default withTheme()(withStyles(styles)(BandwidthBestTest));
