import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import ProgressBar from "../ProgressBar/ProgressBar";
import { IMetaState, IValueState, OptionalTooltip } from "../ControlBar/ControlBar";
import { IField } from "../../interfaces";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import Field from "../ControlBar/Field";
import { TestState } from "../MainContent/MainContent";
import CompanionModal from "../CompanionModal/companionModal";
import useExtensionTracker from "../../helpers/customHooks/useExtensionTracker";
import { useTranslation } from "react-i18next";
import { getCompanionConfig } from "../../helpers/utils";

interface OnboardingProps {
  testState?: TestState;
  setTestState?: Dispatch<SetStateAction<TestState>>;
  theme: any;
  onboardingClasses: any;
  startTest: (skipped?: boolean) => void;
  values: IValueState;
  fields: IField[];
  meta: IMetaState;
  config: TestConfiguration;
  handleChange: (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  showCompanionModal: boolean; // Add showCompanionModal as a prop
  setShowCompanionModal: Dispatch<SetStateAction<boolean>>; // Add setShowCompanionModal as a prop
}

const Onboarding = ({
                      testState,
                      setTestState,
                      theme,
                      onboardingClasses,
                      startTest,
                      values,
                      fields,
                      meta,
                      config,
                      handleChange,
                      showCompanionModal,
                      setShowCompanionModal,
                    }: OnboardingProps) => {
  const arePropsValid = testState && setTestState;

  // Determine if companion is forced based on the config
  const { isCompanionForced, isCompanionSuggested } = getCompanionConfig(config);

  // Use the useExtensionTracker hook to check for the extension
  const isExtensionInstalled = useExtensionTracker();

  const handleStartTestClick = (skipped = false) => {
    if (isCompanionForced && !isExtensionInstalled) {
      setShowCompanionModal(true);
    } else if (isCompanionSuggested) {
      if (!isExtensionInstalled) {
        if (!skipped) {
          setShowCompanionModal(true);
        } else {
          startTest(skipped);
        }
      } else {
        startTest();
      }
    } else {
      startTest();
    }
  };

  useEffect(() => {
    // Re-run this effect when showCompanionModal changes
  }, [showCompanionModal]);

  const { t } = useTranslation(["tests"]);

  if (!arePropsValid) {
    return null;
  }

  return (
    <div className={onboardingClasses.lobbyPage}>
      {testState === "initial" && (
        <div className={onboardingClasses.formSection}>
          <div className={onboardingClasses.formDesc}>
            <Typography variant="h1">{t("tests:onboarding.title")}</Typography>
            <Typography variant="subtitle2">{t("tests:onboarding.message")}</Typography>
          </div>
          <div className={onboardingClasses.formInputs}>
            {Object.keys(values).length > 0 &&
              fields.map((field) => {
                if (meta[field.name]?.hidden) {
                  return null;
                }
                if (config?.inviteFields && config.inviteFields[field.name]) {
                  values[field.name] = config.inviteFields[field.name];
                }
                return (
                  <OptionalTooltip key={field.name} title={values[field.name]} show={meta[field.name]?.readonly}>
                    <Field
                      currentTheme={theme}
                      name={field.name}
                      value={values[field.name]}
                      meta={meta[field.name]}
                      onChange={handleChange(field.name)}
                      config={config}
                      onboardingClasses={onboardingClasses}
                    />
                  </OptionalTooltip>
                );
              })}
          </div>
          <Button
            disabled={config?.invalidInvite}
            data-html2canvas-ignore
            id="start"
            onClick={() => handleStartTestClick()}
            className={onboardingClasses.actionBtn}
            style={{
              backgroundColor: config?.customProjectColor,
            }}
          >
            {t("tests:onboarding.run")}
          </Button>
        </div>
      )}

      {testState === "inProgress" && <ProgressBar customProjectColor={config?.customProjectColor} />}

      {/* Conditionally render the CompanionModal */}
      {showCompanionModal &&
          <CompanionModal skip={isCompanionSuggested ? () => handleStartTestClick(true) : undefined}
                          open={showCompanionModal}/>}
    </div>
  );
};

export default Onboarding;
