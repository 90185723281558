import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";

const styles = (theme: any): any => ({
  customTooltip: {
    backgroundColor: theme.newPalette.text.primary,
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "14px",
    color: theme.newPalette.primary.contrast,
    padding: "4px 8px",
    // fontFamily: "Roboto, sans-serif !important",
    textAlign: "center",
  },
});

function mapStateToProps(state: any) {
  return {
    testResult: state.tests.testResult,
  };
}

function CustomTooltip({ title, children, classes, estimateTime, testResult, value, ...props }: any) {
  const shouldShowTooltip =
    testResult &&
    ((value && typeof value === "string" && value.trim().length > 0) || value === undefined) &&
    title &&
    typeof title === "string" &&
    title.trim().length > 0;

  const tooltipContent = (
    <>
      <div className="arrow" />
      {title}
      {estimateTime && (
        <>
          <br />
          {`${estimateTime}s`}
        </>
      )}
    </>
  );

  return shouldShowTooltip ? (
    <Tooltip title={tooltipContent} classes={{ tooltip: classes.customTooltip }} {...props} placement="top">
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
}

export default connect(mapStateToProps)(withTheme()(withStyles(styles)(CustomTooltip)));
