import { Typography, withStyles, withTheme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { OpenInNewIcon } from "../../../../../assets/icons";

interface SlicedDonutChartProps {
  x: number;
  activeColor?: string;
  statusTitle?: string;
  classes?: any;
  config?: any;
  scoringResult?: any;
  theme?: any;
  hideOpenNewIcon?: boolean;
}

export const scoreStyles = (theme: any): any => ({
  scoreExplainerLink: {
    display: "flex",
    gap: "16px",
    marginBottom: "32px",
    alignItems: "center",
  },
  resultText: {
    color: theme.newPalette.text.primary,
    textAlign: "center",
  },
  btnOverride: {
    border: "none",
    padding: 0,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "&:disabled": {
      backgroundColor: "transparent",
    },
    cursor: "pointer",
  },
  openNewIcon: {
    color: theme.newPalette.text.primary,

    padding: "4px",
    borderRadius: "9999px",
    "&:hover": {
      background: "rgba(36, 53, 68, 0.04)",
    },
    cursor: "pointer",
  },
});

const ScoreDonutChart = ({
                           x,
                           activeColor,
                           statusTitle,
                           classes,
                           config,
                           scoringResult,
                           theme,
                           hideOpenNewIcon
                         }: SlicedDonutChartProps) => {
  const inactiveColor = theme.newPalette.action.hover;
  const statusTextColor = theme.newPalette.text.secondary;
  const fullSlices = Math.floor(x);
  const partialSlicePercentage = (x % 1) * 100;
  const centerX = 141.5;
  const centerY = 141.5;
  const firstLineY = centerY - 12;
  const secondLineDy = 40;
  const paths = [
    "M4.9243 104.496C-1.28612 127.417 -1.62775 151.533 3.93089 174.621C9.48952 197.709 20.7696 219.027 36.7318 236.61L53.9438 220.985C40.6039 206.29 31.177 188.475 26.5315 169.18C21.8861 149.884 22.1716 129.731 27.3617 110.575L4.9243 104.496Z",
    "M71.9223 18.288C40.4418 36.065 16.9598 65.2175 6.29577 99.7619L28.5079 106.619C37.42 77.7496 57.0442 53.3865 83.3529 38.53L71.9223 18.288Z",
    "M210.337 17.8728C189.732 6.39926 166.585 0.258185 143.001 0.00796386C119.418 -0.242257 96.1458 5.40631 75.3013 16.4401L86.1768 36.9857C103.597 27.7646 123.046 23.044 142.755 23.2531C162.464 23.4622 181.808 28.5944 199.028 38.183L210.337 17.8728Z",
    "M277.129 101.162C266.821 66.5042 243.636 37.1073 212.335 19.0064L200.698 39.1304C226.857 54.2575 246.232 78.8249 254.847 107.789L277.129 101.162Z",
    "M246.322 236.55C262.397 218.823 273.713 197.309 279.212 174.02C284.712 150.731 284.215 126.427 277.769 103.382L255.382 109.644C260.769 128.903 261.184 149.214 256.588 168.677C251.992 188.14 242.535 206.12 229.101 220.935L246.322 236.55Z",
  ];

  const { t } = useTranslation(["tests"]);
  let customProjectColor;
  switch (config?.theme) {
    case "salesloft":
      customProjectColor = "#054a2e";
      break;
    case "dialpad":
      customProjectColor = "#7c52ff";
      break;
    default:
      customProjectColor = "";
  }

  return (
    <>
      {config?.scoreExplainerLink ? (
        <div className={classes.scoreExplainerLink}>
          <Typography
            variant="h2"
            className={classes.resultText}
            style={{
              color: customProjectColor,
            }}
          >
            {scoringResult ? t("tests:resultPage.scoring-result-title1") : t("tests:resultPage.scoring-result-title2")}
          </Typography>
          {!hideOpenNewIcon && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={config?.scoreExplainerLink}
              className={`${classes.btnOverride} ${classes.openNewIcon}`}
              style={{ height: 32 }}
            >
              <OpenInNewIcon/>
            </a>
          )}
        </div>
      ) : (
        <Typography
          variant="h2"
          className={classes.resultText}
          style={{
            color: customProjectColor,
          }}
        >
          {t("tests:resultPage.scoring-result-title1")}
        </Typography>
      )}
      <svg width="283" height="283" viewBox="0 0 283 283" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="partialClip">
            <rect width={`${partialSlicePercentage}%`} height="100%"/>
          </clipPath>
        </defs>
        {paths.map((d, index) => (
          <React.Fragment key={index}>
            {index < fullSlices && <path d={d} fill={activeColor}/>}
            {index === fullSlices && (
              <>
                <path d={d} fill={activeColor} clipPath="url(#partialClip)"/>
                <path d={d} fill={inactiveColor}/>
              </>
            )}
            {index > fullSlices && <path d={d} fill={inactiveColor}/>}
          </React.Fragment>
        ))}
        <text x={centerX} y={firstLineY} textAnchor="middle" alignmentBaseline="middle" className="scoring">
          <tspan
            style={{
              fill: activeColor,
              fontWeight: 700,
              fontSize: "38px",
              lineHeight: "57px",
              letterSpacing: "-0.5px",
            }}
            id="scoring"
          >
            {x}/5
          </tspan>
        </text>
        <text x={centerX} y={centerY} dy={secondLineDy} textAnchor="middle" alignmentBaseline="middle">
          <tspan
            style={{
              fill: statusTextColor,
              fontSize: "20px",
              lineHeight: "35px",
              letterSpacing: "0.15px",
              textTransform: "capitalize",
            }}
            id="scoring-status"
          >
            {statusTitle}
          </tspan>
        </text>
      </svg>
    </>
  );
};

export default withTheme()(withStyles(scoreStyles)(ScoreDonutChart));
