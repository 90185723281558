import { Typography, withStyles, withTheme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { OpenInNewIcon } from "../../../../../assets/icons";
import { scoreStyles } from "./ScoreDonutChart";

interface Props {
  chartClass: string;
  classes: any;
  titleColor?: string;
  chartTitle?: string;
  config?: any;
  scoringResult?: any;
  hideOpenNewIcon?: boolean;
}

const TestStatusChart = ({ chartClass, classes, titleColor, chartTitle, config, scoringResult, hideOpenNewIcon }: Props) => {
  const { t } = useTranslation(["tests"]);

  let customProjectColor;
  switch (config?.theme) {
    case "salesloft":
      customProjectColor = "#054a2e";
      break;
    case "dialpad":
      customProjectColor = "#7c52ff";
      break;
    default:
      customProjectColor = "";
  }

  return (
    <>
      {config?.scoreExplainerLink ? (
        <div className={classes.scoreExplainerLink}>
          <Typography
            variant="h2"
            className={classes.resultText}
            style={{
              color: customProjectColor,
            }}
          >
            {scoringResult ? t("tests:resultPage.scoring-result-title1") : t("tests:resultPage.scoring-result-title2")}
          </Typography>
          {!hideOpenNewIcon && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={config?.scoreExplainerLink}
              className={`${classes.btnOverride} ${classes.openNewIcon}`}
              style={{ height: 32 }}
            >
              <OpenInNewIcon />
            </a>
          )}
        </div>
      ) : (
        <Typography
          variant="h2"
          className={classes.resultText}
          style={{
            color: customProjectColor,
          }}
        >
          {t("tests:resultPage.no-scoring-result-title1")}
        </Typography>
      )}
      <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="100" cy="100" rx="95" ry="95" strokeWidth="10px" className={chartClass} />
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          className={`${classes.chartTitle} ${titleColor}`}
          fill={titleColor}
          id="test-status"
        >
          {chartTitle}
        </text>
      </svg>
    </>
  );
};

export default withTheme()(withStyles(scoreStyles)(TestStatusChart));
